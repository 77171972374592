table.mygridview {
    width: 100%;
    border-color: #e6e6e6;
    margin-bottom: 20px;
}

    table.mygridview th {
        font-weight: bold;
        padding: 6px;
        font-size: 1.1em;
    }

    table.mygridview td {
        font-size: 1em;
        padding: 3px;
    }


.edtCodigo {
    width: 10% !important;
}

.edtDescricao {
    width: 90% !important;
}

.edtHalfDescricao {
    width: 30% !important;
}

.edtHalfDescricao2 {
    width: 45% !important;
}
.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}.form-registrar {

    padding: 15px;
    margin: 0 auto;
}

    .form-signin .form-signin-heading, .form-signin .checkbox {
        margin-bottom: 10px;
    }

    .form-signin .checkbox {
        font-weight: normal;
    }

    .form-signin .form-control {
        position: relative;
        font-size: 16px;
        height: auto;
        padding: 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

        .form-signin .form-control:focus {
            z-index: 2;
        }

    .form-signin input[type="text"] {
        margin-bottom: -1px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

.account-wall {
    margin-top: 20px;
    padding: 40px 0px 20px 0px;
    background-color: #f7f7f7;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.login-title {
    color: #555;
    font-size: 18px;
    font-weight: 400;
    display: block;
}

.profile-img {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.need-help {
    margin-top: 10px;
}

.new-account {
    display: block;
    margin-top: 10px;
}

html,body{
    min-height:100%;
}

body {
    background: linear-gradient(0deg, rgba(46, 247, 138, 0.3) 0%, rgba(25,131,194,0) 15%);
}


